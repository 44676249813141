const DefaultHeader = {
  // 'Access-Control-Allow-Origin': 'cors',
  // 'Content-Type': 'application/json',
};

export const fetchAPI = async (path, data, options) => {
  const { method = 'GET', header } = options || {};
  const requestOption =
    method === 'GET'
      ? { method, headers: { ...DefaultHeader, ...header } }
      : {
          method,
          headers: { ...DefaultHeader, ...header },
          body: parseObjToFormData(data || {}),
        };
  const url = `${process.env.REACT_APP_URL_HOST}${path}`;

  console.log('url, requestOption', url, requestOption);
  // return await fetch('https://apisdk.ctchat.live/ts', requestOption);
  return await fetch(url, requestOption);
};

const parseObjToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, val]) => {
    formData.append(key, val);
  });
  return formData;
};
