import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Routes,
  redirect,
} from "react-router-dom";
import Dice from "../pages/dice";

const router = createBrowserRouter(
  // createRoutesFromElements(
  [
    {
      path: "/dice",
      element: <Dice />,
    },
    {
      path: "a",
      element: <div>Hello world13!</div>,
      // loader: rootLoader,
      children: [
        {
          path: "team",
          element: <div>Hello world2!</div>,
          // loader: teamLoader,
        },
      ],
    },
    {
      path: "*",
      children: [{ index: true, loader: async () => redirect("/dice") }],
    },
  ]
  // <Routes>
  //   <Route path="/*" element={<div>Hello world13!</div>}>
  //     <Route path="game" element={<div>Hello world2!</div>}></Route>
  //   </Route>
  // </Routes>
  // )
  //   [
  //   {
  //     path: "/",
  //     element: <div>Hello world!</div>,
  //   },
  // ]
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
