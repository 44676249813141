export const DICE_2D = {
  diceFace1: 'assets/dice/2DFace/diceFace_one.svg',
  diceFace2: 'assets/dice/2DFace/diceFace_two.svg',
  diceFace3: 'assets/dice/2DFace/diceFace_three.svg',
  diceFace4: 'assets/dice/2DFace/diceFace_four.svg',
  diceFace5: 'assets/dice/2DFace/diceFace_five.svg',
  diceFace6: 'assets/dice/2DFace/diceFace_six.svg',
};

export const DICE_EVENODD = {
  odd: {
    en: 'assets/dice/2DFace/diceFace_odd_en.svg',
    zh: 'assets/dice/2DFace/diceFace_odd_zh.svg',
  },
  even: {
    en: 'assets/dice/2DFace/diceFace_even_en.svg',
    zh: 'assets/dice/2DFace/diceFace_even_zh.svg',
  },
};

export const ROLL_N_WIN_IMG = {
  zh: 'assets/dice/rollnwin/rollnwin_zh.svg',
  en: 'assets/dice/rollnwin/rollnwin_en.png',
};

export const DICE_RESULT = {
  SMALL: 1,
  BIG: 2,
  TRIPLE: 3,
  ODD: 1,
  EVEN: 2,
};
