import { DICE_RESULT } from './config';
import i18n from 'i18next';
export const getDiceNumberResult = (big_small = 0, arr = [0, 0, 0]) => {
  //this will return the dice number if all 3 number is the same
  //return the total sum of the 3 number if its not triple
  if (big_small === DICE_RESULT.TRIPLE) {
    return i18n.t('DiceTripleNumber', { number: arr[0] });
  } else {
    if (arr.length !== 3) {
      return 0;
    } else {
      return i18n.t('DiceTotal', { total: arr[0] + arr[1] + arr[2] });
    }
  }
};

export const getDiceResultText = (big_small) => {
  if (big_small === DICE_RESULT.SMALL) {
    return i18n.t('GameResultSmall');
  } else if (big_small === DICE_RESULT.BIG) {
    return i18n.t('GameResultBig');
  } else if (big_small === DICE_RESULT.TRIPLE) {
    return i18n.t('GameResultTriple');
  } else {
    return '';
  }
};
