import { DicePhaser } from '../../components/DicePhaser/wrapper';
import { DiceHistory } from '../../components/DiceHistory';
import { useQuery } from '../../hooks/useQuery';
import { DiceBtns } from '../../components/DiceControls/DiceBtns';
import { useDiceData } from './useDiceData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDiceStatus } from '../../store/diceSlice';
import { useDiceAction } from './useDiceAction';
import { LOCALSTORAGE_KEYS } from '../../const/webStorage';
import { DiceLabels } from '../../components/DiceLabel/DiceLabels';
import { GameStatus, setLanguage } from '../../store/commonSlice';
import { useGameApi } from '../../hooks/useGameApi';
import { useTranslation } from 'react-i18next';
import { DiceSetting } from '../../components/DiceSetting.js/DiceSetting';
import DiceAutoGame from '../../components/DiceAutoGame';

const HistoryWidth = 570;

export default function DicePage() {
  const query = useQuery();
  const gameId = useSelector((s) => s.common.gameId);
  const lang = useSelector((s) => s.common.lang);
  const isAuto = useSelector((s) => s.dice.isAuto);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (gameId) {
      init();
    }
  }, [gameId]);
  const init = () => {
    getHistory();
    getStatus();
  };
  useEffect(() => {
    const token = query.get('token');
    localStorage.setItem(LOCALSTORAGE_KEYS.HostToken, token);
    const lang1 = query.get('lang');
    if (lang1) {
      dispatch(setLanguage(lang1));
    }
    init();
  }, [query, i18n]);
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  const { history, historyTotal, status } = useDiceData();
  const { startDiceGame, closeDiceGame, completeDiceGame, getHistory } =
    useDiceAction();
  const { getStatus } = useGameApi();
  return (
    <div
      className='absolute inset-0 flex flex-row w-full'
      style={{ backgroundImage: `url(assets/dice/dice-bg.jpeg)` }}
    >
      <div
        style={{ width: `calc(100% - ${HistoryWidth}px)` }}
        className='relative flex flex-col overflow-hidden justify-center'
      >
        <DicePhaser action={{ onRequestResult: completeDiceGame }} />
        <DiceLabels />
        <div className='flex flex-none'>
          <DiceBtns
            status={status}
            action={{
              onOpen: startDiceGame,
              onClose: closeDiceGame,
              onRoll: () => {
                dispatch(setDiceStatus(GameStatus.ROLLING));
              },
            }}
          />
        </div>
        {isAuto && (
          <DiceAutoGame
            startGame={startDiceGame}
            closeGame={closeDiceGame}
            rollGame={() => dispatch(setDiceStatus(GameStatus.ROLLING))}
          />
        )}
      </div>
      <div
        style={{ width: `${HistoryWidth}px` }}
        className='absolute top-3 bottom-3 right-0'
      >
        <DiceHistory history={history} total={historyTotal} />
      </div>
      <div className='absolute right-5 top-5'>
        <DiceSetting />
      </div>
    </div>
  );
}
