import Phaser from 'phaser';
import { P_IMAGE } from '../config';
import { createHolderContainer } from '../phaserComponents/holderContainer';
export default class init extends Phaser.Scene {
  constructor() {
    super({ key: 'init' });
  }
  preload() {
    Object.entries(P_IMAGE).forEach(([key, val]) => {
      this.load.image(key, val);
    });
  }
  create() {
    this.scene.stop('roll');
    this.scene.stop('result');
    createHolderContainer(this);
  }
  update() {}
}
