import { useCallback, useEffect } from 'react';
import { LOCALSTORAGE_KEYS } from '../const/webStorage';
import { fetchAPI } from '../service/api';
import { useDispatch, useSelector } from 'react-redux';
import { URL_GAME } from '../service/url';
import { setGameStatus } from '../store/commonSlice';

export const useGameApi = () => {
  const dispatch = useDispatch();
  const gameId = useSelector((s) => s.common.gameId);

  const getHeader = () => {
    const token = localStorage.getItem(LOCALSTORAGE_KEYS.HostToken);
    return { Authorization: `Bearer ${token}` };
  };

  const commonApi = useCallback(async (url, data, option = {}) => {
    try {
      return await fetchAPI(url, data, {
        header: { ...getHeader(), ...option?.heaader },
        ...option,
      }).then((data) => data.json());
    } catch (e) {
      console.log('exception api', e);
      return { code: 200, data: null, msg: e };
    }
  }, []);

  const getStatus = useCallback(
    async (data = {}, option = {}) => {
      const payload = { stream_game_id: gameId };
      return await commonApi(
        URL_GAME.getStatus,
        { ...payload, ...data },
        option
      ).then((d) => {
        if (d?.data) {
          dispatch(setGameStatus(d.data.status));
        }
      });
    },
    [gameId]
  );
  const getSessions = useCallback(
    async (payload, option = {}) => {
      return await commonApi(
        URL_GAME.getHistory({ limit: payload?.limit || 20, gameId }),
        {},
        option
      ).then((d) => {
        return d;
      });
    },
    [gameId]
  );

  const getStreamGames = useCallback(async () => {
    return await commonApi(URL_GAME.getStreamGames).then((data) => {
      // todo dispatch all games
    });
  }, []);

  const requestGameOpen = useCallback(
    async (data = {}, option = {}) => {
      return await commonApi(
        URL_GAME.startSession,
        { ...data, stream_game_id: gameId },
        { ...option, method: 'POST' }
      ).then((d) => {
        getStatus();
      });
    },
    [gameId]
  );
  const requestGameClose = useCallback(
    async (data = {}, option = {}) => {
      return await commonApi(
        URL_GAME.closeSession,
        { ...data, stream_game_id: gameId },
        { ...option, method: 'POST' }
      ).then((d) => {
        getStatus();
      });
    },
    [gameId]
  );
  const requestGameComplete = useCallback(
    async (data = {}, option = {}) => {
      return await commonApi(
        URL_GAME.completeSession,
        { ...data, stream_game_id: gameId },
        { ...option, method: 'POST' }
      );
    },
    [gameId]
  );
  return {
    getSessions,
    getStatus,
    getStreamGames,
    requestGameOpen,
    requestGameClose,
    requestGameComplete,
  };
};
