import { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import { Config, DiceContainerId, RollingTime } from './config';
import roll from './phaserScene/roll';
import init from './phaserScene/init';
import result from './phaserScene/result';
import { useDispatch, useSelector } from 'react-redux';
import { GameStatus } from '../../store/commonSlice';
import { LOCALSTORAGE_KEYS } from '../../const/webStorage';

export const DicePhaser = ({ action }) => {
  const gameRef = useRef(null);
  const showResultAnimationRef = useRef(null);
  const status = useSelector((s) => s.dice.status);
  const diceResult = useSelector((s) => s.dice.result);
  const { onRequestResult } = action;
  const dispatch = useDispatch();
  const startGame = (parent) => {
    return new Phaser.Game({
      ...Config,
      parent,
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 3 },
          debug: true,
        },
      },
      scene: [init, roll, result],
    });
  };

  useEffect(() => {
    if (!gameRef.current) {
      gameRef.current = startGame(DiceContainerId);
    }
  }, []);

  const onRollEnd = async () => {
    onRequestResult();
  };
  useEffect(() => {
    if (!gameRef.current) {
      return;
    }
    console.log(
      'Log:showResultAnimationRef.current',
      showResultAnimationRef.current,
      diceResult
    );
    if (
      diceResult?.result &&
      showResultAnimationRef.current &&
      (status === GameStatus.ROLLING || status === GameStatus.ROLLINGEND)
    ) {
      gameRef.current.scene.start('result', {
        // showAnimation: showResultAnimationRef.current,
        dices: diceResult?.result?.rolls,
      });
      return;
    }
    switch (status) {
      case GameStatus.ROLLING:
        localStorage.removeItem(LOCALSTORAGE_KEYS.RollEnd);
        gameRef.current.scene.start('roll', {
          onEnd: () => {
            showResultAnimationRef.current = true;
            onRollEnd();
          },
        });
        setTimeout(() => {
          localStorage.setItem(LOCALSTORAGE_KEYS.RollEnd, 1);
        }, RollingTime);
        break;
      case GameStatus.OPEN:
      case GameStatus.SETTLED:
        showResultAnimationRef.current = false;
        gameRef.current.scene.start('init');
        break;
    }
  }, [status, diceResult, onRollEnd]);

  return <div id={DiceContainerId} />;
};
