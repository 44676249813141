export const URL_GAME = {
  // all
  getGames: '/game/streamGame/getStreamGames',
  // status
  closeSession: '/game/streamGame/closeSession',
  completeSession: '/game/streamGame/completeSession',
  getHistory: ({ maxId = '', limit = '', gameId = '' } = {}) =>
    `/game/streamGame/getSessions?max_session_id=${maxId}&limit=${limit}&stream_game_id=${gameId}`,
  getStatus: '/game/streamGame/getLatestStatus',
  startSession: '/game/streamGame/startSession',
};
