import { DiceSize } from './config';

export const generateDice = (phaser, prop) => {
  const { x, y, img } = prop;
  const newDice = phaser.add.image(x, y, img);
  newDice.setDisplaySize(DiceSize, DiceSize);
  return newDice;
};

const NumofAvailablePosAroundCircle = 6;
export const generatePos = (x, y, radius, count = 3) => {
  const allPos = new Array(NumofAvailablePosAroundCircle)
    .fill(0)
    .map((_, idx) => idx)
    .sort(() => Math.random() - 0.5);

  const randomStart = Math.random();
  return new Array(count).fill(0).map((_, idx) => {
    if (!allPos[idx]) {
      return [x, y];
    }
    const yOffset =
      y -
      radius *
        Math.sin(
          ((Math.PI * 2) / NumofAvailablePosAroundCircle) * allPos[idx] +
            randomStart
        );
    const xOffset =
      x +
      radius *
        Math.cos(
          ((Math.PI * 2) / NumofAvailablePosAroundCircle) * allPos[idx] +
            randomStart
        );
    return [xOffset, yOffset];
  });
};
