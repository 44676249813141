import Phaser from 'phaser';
import { createHolderContainer } from '../phaserComponents/holderContainer';
import { ShakeRangeX, ShakeRangeY } from '../config';
import { LOCALSTORAGE_KEYS } from '../../../const/webStorage';

export default class roll extends Phaser.Scene {
  constructor() {
    super({ key: 'roll' });
  }
  preload() {
    this.load.audio('chaacha', '../assets/dice/sound/diceshake.mp3');
    this.load.audio('table', '../assets/dice/sound/table-smash.mp3');
  }
  create(data) {
    this.scene.stop('init');
    const chachaSound = this.sound.add('chaacha', { loop: true });
    const tableSound = this.sound.add('table', { loop: false });
    const container = createHolderContainer(this);
    const playSmash = () => {
      chachaSound.stop();
      this.tweens.chain({
        targets: container,
        tweens: [
          { x: 0, y: -ShakeRangeY, angle: 0, duration: 100, ease: 'cubic.out' },
          {
            x: 0,
            y: 0,
            delay: 500,
            duration: 80,
            ease: 'cubic.out',
            onComplete: () => {
              tableSound.play();
              data?.onEnd?.();
            },
          },
        ],
      });
    };

    const rollComplete = () => {
      const rollEnd = localStorage.getItem(LOCALSTORAGE_KEYS.RollEnd);
      if (rollEnd) {
        return playSmash();
      } else {
        return playRoll();
      }
    };
    const playRoll = () => {
      const x = Phaser.Math.Between(-ShakeRangeX, ShakeRangeX);
      const y = Phaser.Math.Between(-ShakeRangeY, -ShakeRangeY / 2);
      const angle = Phaser.Math.Between(-15, 15);
      return this.add.tween({
        targets: container,
        x,
        y,
        angle,
        duration: 100,
        ease: 'cubic.out',
        onComplete: rollComplete,
      });
    };
    chachaSound.play();
    playRoll();
  }
  update() {}
}
