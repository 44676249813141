export const GAME_ID = {
  DICE: 1,
};
export const AUTO_GAME_SETTING = {
  GameResultShowTime: 5,
  GameDurationMin: 5,
  GameDurationMax: 60,
  GameIntervalMin: 5,
  GameIntervalMax: 60,
  Languages: [
    { key: 'zh', text: '简体中文', icon: 'assets/dice/country/CN.svg' },
    { key: 'en', text: 'English', icon: 'assets/dice/country/UK.svg' },
  ],
};
