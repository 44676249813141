import { configureStore } from '@reduxjs/toolkit';
import diceSlice from './diceSlice';
import commonSlice from './commonSlice';

export const store = configureStore({
  reducer: {
    dice: diceSlice.reducer,
    common: commonSlice.reducer,
  },
});
