import { useCallback } from 'react';
import { GameStatus } from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setDiceAutoGame } from '../../store/diceSlice';

const BtnStyleInactive = 'opacity-50';
const BtnStyleActive = '';
const BtnStyleHover = 'hover:-translate-y-1 hover:scale-[1.05]';

export const BtnStatus = {
  Active: 'Active',
  InActive: 'InActive',
};
const BtnStyle = {
  [BtnStatus.Active]: BtnStyleActive,
  [BtnStatus.InActive]: BtnStyleInactive,
};

const BTN_IMG_ROOT = '/assets/dice/buttons';
const BtnImg = {
  zh: {
    start: BTN_IMG_ROOT + '/zh/start.svg',
    close: BTN_IMG_ROOT + '/zh/close.svg',
    roll: BTN_IMG_ROOT + '/zh/roll.svg',
    autoStart: BTN_IMG_ROOT + '/zh/autoStart.svg',
    autoEnd: BTN_IMG_ROOT + '/zh/autoEnd.svg',
  },
  en: {
    start: BTN_IMG_ROOT + '/en/start.svg',
    close: BTN_IMG_ROOT + '/en/close.svg',
    roll: BTN_IMG_ROOT + '/en/roll.svg',
    autoStart: BTN_IMG_ROOT + '/en/autoStart.svg',
    autoEnd: BTN_IMG_ROOT + '/en/autoEnd.svg',
  },
};
export const DiceBtns = ({ status, action = {} }) => {
  const lang = useSelector((s) => s.common.lang);
  const isAuto = useSelector((s) => s.dice.isAuto);
  const dispatch = useDispatch();
  const { onOpen, onClose, onRoll } = action;
  const getOpenBtnStatus = useCallback(() => {
    return [
      GameStatus.UNKNOWN,
      GameStatus.SETTLED,
      GameStatus.COMPLETED,
      GameStatus.ROLLINGEND,
    ].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  const getCloseBtnStatus = useCallback(() => {
    return [GameStatus.OPEN].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  const getRollBtnStatus = useCallback(() => {
    return [GameStatus.CLOSED].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  return (
    <div className='flex flex-1 flex-row my-5 gap-5 justify-between'>
      <div className='flex-1' />
      <div className='flex-1 grid grid-cols-2 grid-flow-row gap-x-5'>
        {BtnImg[lang]?.start && (
          <DiceControlBtn
            img={BtnImg[lang].start}
            status={getOpenBtnStatus()}
            onPress={onOpen}
          ></DiceControlBtn>
        )}
        {BtnImg[lang]?.close && (
          <DiceControlBtn
            img={BtnImg[lang].close}
            status={getCloseBtnStatus()}
            onPress={onClose}
          ></DiceControlBtn>
        )}
        {BtnImg[lang]?.roll && (
          <DiceControlBtn
            img={BtnImg[lang].roll}
            status={getRollBtnStatus()}
            onPress={onRoll}
          ></DiceControlBtn>
        )}
        {BtnImg[lang]?.autoEnd && isAuto && (
          <DiceControlBtn
            img={BtnImg[lang].autoEnd}
            status={BtnStatus.Active}
            onPress={() => {
              dispatch(setDiceAutoGame(!isAuto));
            }}
          ></DiceControlBtn>
        )}
        {BtnImg[lang]?.autoStart && !isAuto && (
          <DiceControlBtn
            img={BtnImg[lang].autoStart}
            status={BtnStatus.Active}
            onPress={() => {
              dispatch(setDiceAutoGame(!isAuto));
            }}
          ></DiceControlBtn>
        )}
      </div>

      <div className='flex-1' />
    </div>
  );
};

const DiceControlBtn = ({ img, status, onPress }) => {
  return (
    <button
      disabled={status !== BtnStatus.Active}
      onClick={onPress}
      className={`${
        status === BtnStatus.Active
          ? BtnStyle.Active
          : status === BtnStatus.InActive
          ? BtnStyle.InActive
          : ''
      } ${
        status === BtnStatus.Active && BtnStyleHover
      } rounded flex-[2] py-2 place-content-center flex`}
    >
      <img src={img} />
    </button>
  );
};
