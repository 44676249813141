import Phaser from 'phaser';
import { generateDice, generatePos } from '../util';
import { Config, PosDiceInit, PosHolderInit } from '../config';
import { createPlate } from '../phaserComponents/holderContainer';

export default class result extends Phaser.Scene {
  constructor() {
    super({ key: 'result' });
  }
  preload() {
    this.scene.stop('init');
    this.scene.stop('roll');
  }
  create(data) {
    const plate = createPlate(this);
    const pos = generatePos(PosDiceInit.x, PosDiceInit.y, PosDiceInit.radius);
    const diceArr = pos.map(([x, y], idx) => {
      return generateDice(this, {
        x,
        y,
        img: 'diceValue' + data.dices[idx],
      });
    });

    const End = { x: Config.width * 2, y: -300 };
    const curve = new Phaser.Curves.CubicBezier(
      new Phaser.Math.Vector2(PosHolderInit.x, PosHolderInit.y),
      new Phaser.Math.Vector2(PosHolderInit.x, End.y * 0.8),
      new Phaser.Math.Vector2((End.x + PosHolderInit.x) / 2, End.y),
      new Phaser.Math.Vector2(Config.width, End.y)
    );

    const holder = this.add.follower(
      curve,
      PosHolderInit.x,
      PosHolderInit.y,
      'holder'
    );
    holder.setDisplaySize(PosHolderInit.width, PosHolderInit.height);
    holder.startFollow({
      duration: 1000,
      yoyo: false,
      ease: 'Sine.easeIn',
    });
  }
  update() {}
}
