import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDiceResult } from '../../store/diceSlice';
import {
  GameStatus,
  setGameStatus,
  setSessionHistory,
} from '../../store/commonSlice';
import { useGameApi } from '../../hooks/useGameApi';

export const useDiceAction = () => {
  const dispatch = useDispatch();
  const {
    getSessions,
    requestGameOpen,
    requestGameClose,
    requestGameComplete,
  } = useGameApi();

  const getHistory = async (prop) => {
    await getSessions({ limit: 300 }).then((data) => {
      console.log('Log:data', data);
      dispatch(setSessionHistory(data?.data));
      // dispatch(setDiceResult(data?.data?.sessions?.[0]));
    });
  };

  const startDiceGame = useCallback(async () => {
    await requestGameOpen();
    dispatch(setDiceResult(null));
  }, []);

  const closeDiceGame = useCallback(async () => {
    await requestGameClose();
  }, []);

  const completeDiceGame = useCallback(async () => {
    await requestGameComplete()
      .then(() => {
        getHistory();
      })
      .then(() => {
        dispatch(setGameStatus(GameStatus.ROLLINGEND));
      });
  }, []);
  return {
    closeDiceGame,
    completeDiceGame,
    getHistory,
    startDiceGame,
  };
};
