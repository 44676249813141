import { useEffect, useState } from 'react';
import { DICE_2D, DICE_EVENODD, DICE_RESULT } from './config';
import { getDiceNumberResult, getDiceResultText } from './utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const ResultRow = ({
  gameIdx,
  diceResult,
  condense = false,
  isFirst,
}) => {
  const { rolls = [], big_small } = diceResult?.result || {};
  const { t } = useTranslation();
  if (isFirst && rolls?.length === 0) {
    return null;
  }
  return (
    <>
      {!condense ? (
        <div className='text-white whitespace-nowrap'>
          {t('GameRound', { idx: gameIdx })} :{' '}
        </div>
      ) : null}
      <div
        className={`text-left whitespace-nowrap min-w-[150px] ${
          big_small === DICE_RESULT.BIG
            ? 'text-[#04A009]'
            : big_small === DICE_RESULT.SMALL
            ? 'text-[#DE173E]'
            : 'text-white'
        } font-semibold`}
      >
        {getDiceResultText(big_small)}
        {getDiceNumberResult(big_small, rolls)}
      </div>
      <ResultDicesIcon diceResult={diceResult} condense={condense} />
    </>
  );
};

const ResultDicesIcon = ({ diceResult, condense }) => {
  const { rolls, odd_even } = diceResult?.result || {};
  const lang = useSelector((s) => s.common.lang);
  const [img4th, setImg4th] = useState(null);
  useEffect(() => {
    const imgToIcon = {
      [DICE_RESULT.ODD]: DICE_EVENODD.odd[lang],
      [DICE_RESULT.EVEN]: DICE_EVENODD.even[lang],
    };
    setImg4th(imgToIcon[odd_even]);
  }, [lang, odd_even]);
  return (
    <div className='flex flex-none flex-row gap-2 items-center justify-end pr-2'>
      {rolls?.map((number, index) => {
        const icon = DICE_2D[`diceFace${number}`] || null;
        return (
          <img
            key={`${index}`}
            width={condense ? 60 : 30}
            height={condense ? 60 : 30}
            src={icon}
            alt={number}
          />
        );
      })}
      {!condense && img4th && (
        <img width={30} height={30} src={img4th} alt={odd_even} />
      )}
    </div>
  );
};
