import {  PosHolderInit, PosPlateInit } from '../config';

export const createPlate = (phaser) => {
  const plate = phaser.add.image(PosPlateInit.x, PosPlateInit.y, 'plate');
  plate.setDisplaySize(
    (PosPlateInit.height / plate.height) * plate.width,
    PosPlateInit.height
  );
  console.log('Log:plate', plate);
  return plate;
};
export const createHolder = (phaser) => {
  const holder = phaser.add.image(PosHolderInit.x, PosHolderInit.y, 'holder');
  holder.setDisplaySize(PosHolderInit.width, PosHolderInit.height);
  return holder;
};
export const createHolderContainer = (phaser) => {
  const plate = createPlate(phaser);
  const holder = createHolder(phaser);
  const whole = phaser.add.container();
  whole.add([plate, holder]);
  return whole;
};
