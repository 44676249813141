import Phaser from 'phaser';

export const P_IMAGE = {
  plate: 'assets/dice/Dice_bottom.png',
  holder: 'assets/dice/Dice_shaker_cups.png',
  // diceValue1: 'assets/dice/Dice_1@3x-8.png',
  // diceValue2: 'assets/dice/Dice_2@3x-8.png',
  // diceValue3: 'assets/dice/Dice_3@3x-8.png',
  // diceValue4: 'assets/dice/Dice_4@3x-8.png',
  // diceValue5: 'assets/dice/Dice_5@3x-8.png',
  // diceValue6: 'assets/dice/Dice_6@3x-8.png',
  diceValue1: 'assets/dice/3DFace/dice_1@3x-8.png',
  diceValue2: 'assets/dice/3DFace/dice_2@3x-8.png',
  diceValue3: 'assets/dice/3DFace/dice_3@3x-8.png',
  diceValue4: 'assets/dice/3DFace/dice_4@3x-8.png',
  diceValue5: 'assets/dice/3DFace/dice_5@3x-8.png',
  diceValue6: 'assets/dice/3DFace/dice_6@3x-8.png',
};

export const Config = {
  type: Phaser.AUTO,
  width: 1240,
  height: 700,
  transparent: true,
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  audio: {
    disableWebAudio: true,
  },
};

const PlatHeight = 150;
export const ShakeRangeX = Config.width / 4;
export const ShakeRangeY = Config.height * 0.4;
export const RollingTime = 2000;
export const DiceContainerId = 'dice-container';

// CAUSTIONS: DO NOT MODIFY BELOW CONST VALUES
// CAUSTIONS: DO NOT MODIFY BELOW CONST VALUES
// CAUSTIONS: DO NOT MODIFY BELOW CONST VALUES

const ratio = PlatHeight / 714;
export const DiceSize = PlatHeight / 4.8;

export const PlateCenter = {
  x: Config.width * 0.5,
  y: Config.height * 0.8,
};

// plate img dimension 1031*714
export const PosPlateInit = {
  ...PlateCenter,
  width: 1031 * ratio,
  height: PlatHeight,
};
// holder img dimension 883*1230
export const PosHolderInit = {
  ...PlateCenter,
  y: PlateCenter.y - PosPlateInit.height * 0.55,
  width: 883 * ratio,
  height: 1230 * ratio,
};

export const PosDiceInit = {
  ...PlateCenter,
  y: PlateCenter.y - DiceSize / 3,
  radius: DiceSize * 1.15,
};
