import { useTranslation } from 'react-i18next';

export const CountDown = ({ number, labelKey }) => {
  const { t } = useTranslation();
  return (
    <div className='absolute w-[160px] h-[160px] rounded-full border-4 border-white flex flex-col items-center justify-center text-white top-32 left-12'>
      <div className='text-[50px] font-semibold'>{number}</div>
      <div className='text-[22px] font-semibold'>{t(labelKey)}</div>
    </div>
  );
};
