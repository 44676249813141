import { useSelector } from 'react-redux';
import { CountDown } from './CountDown';
import { useEffect, useRef, useState } from 'react';
import { GameStatus } from '../../store/commonSlice';
import { AUTO_GAME_SETTING } from '../../const/DiceConfig';

export default function ({ startGame, closeGame, rollGame }) {
  const diceStatus = useSelector((s) => s.dice.status);
  const autoGameDuration = useSelector((s) => s.dice.autoDuration);
  const autoGameInterval = useSelector((s) => s.dice.autoInterval);
  const [countDown, setCountDown] = useState(autoGameDuration);
  const isRollingRef = useRef();
  const countdownIntervalRef = useRef();
  const isGameBreakRef = useRef();
  const gameResultShowTimeRef = useRef();
  useEffect(() => {
    switch (diceStatus) {
      case GameStatus.UNKNOWN:
      case GameStatus.COMPLETED:
      case GameStatus.SETTLED:
      case GameStatus.ROLLINGEND:
        clearTimeout(gameResultShowTimeRef.current);
        gameResultShowTimeRef.current = setTimeout(() => {
          isGameBreakRef.current = true;
          isRollingRef.current = false;
          setCountDown(autoGameInterval);
          startCountDown();
        }, AUTO_GAME_SETTING.GameResultShowTime * 1000);
        break;
      case GameStatus.OPEN:
        setCountDown(autoGameDuration);
        isRollingRef.current = false;
        isGameBreakRef.current = false;
        startCountDown();
        break;
      case GameStatus.CLOSED:
        isGameBreakRef.current = false;
        if (!isRollingRef.current) {
          rollGame();
          isRollingRef.current = true;
        }
        break;
      default:
        break;
    }
  }, [diceStatus, autoGameDuration, autoGameInterval]);
  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(countdownIntervalRef.current);
      if (isGameBreakRef.current) {
        if (GameStatus.OPEN !== diceStatus) {
          setCountDown(1);
          startGame();
        }
      } else {
        if (GameStatus.OPEN === diceStatus) {
          closeGame();
        }
      }
    }
  }, [diceStatus, countDown]);

  const startCountDown = () => {
    clearInterval(countdownIntervalRef.current);
    countdownIntervalRef.current = setInterval(() => {
      setCountDown((a) => a - 1);
    }, 1000);
  };
  console.log('diceStatus', diceStatus);

  return (
    <>
      {GameStatus.OPEN === diceStatus && (
        <CountDown number={countDown} labelKey='DicePleaseBet' />
      )}
      {isGameBreakRef.current && GameStatus.OPEN !== diceStatus && (
        <CountDown number={countDown} labelKey='DiceAutoBreak' />
      )}
    </>
  );
}
